import React, { useState } from "react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import Navbar from "../components/MenuNavbar.jsx";
import FrostEffect from "../components/FrostEffectSecond";
import Footer from "../components/Footer";
import image1 from "../assets/img/Menu/Weekly.jpg"
import image2 from "../assets/img/Menu/Menu1.jpeg"
import image3 from "../assets/img/Menu/Menu2.jpeg"
import image4 from "../assets/img/Menu/Menu3.jpeg"
import image5 from "../assets/img/Menu/Specials.jpg"
import "./menu.css"

function Menu() {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const images = [image1, image2, image3, image4, image5]; // Update with all your image imports

  function toggleFullscreen(index) {
    setCurrentImageIndex(index);
    setIsFullscreen(prevState => !prevState);
  }

  return (
    <div>
      {isFullscreen && (
        <div className="fullscreen flex justify-center items-center bg-black bg-opacity-80 fixed w-full h-full z-50"  onClick={toggleFullscreen}>
          <img
            className="w-[360px] h-[480px] md:h-[680px] md:w-[510px]" src={images[currentImageIndex]} alt="Fullscreen Image"
          />
        </div>
      )}
      <Navbar />
      <FrostEffect />
      <div className="flex flex-col items-center justify-center py-24 gap-6 overflow-x-hidden">
        <h1 className="text-4xl font-header text-white md:text-7xl md:py-10">
          Menu
        </h1>
        <div className="p-10 pb-24 flex flex-col items-center gap-6 bg-black w-screen">
          <h2 className="font-body font-bold text-white text-3xl text-center md:text-5xl">
          OBĚDOVÉ MENU
          </h2>
            <img src = {image1} className="rounded-3xl xl:w-[30%]" onClick={() => toggleFullscreen(0)}></img>
        </div>
        <div className="p-10 pb-24 flex flex-col items-center gap-6 bg-black w-screen">
          <h2 className="font-body font-bold text-white text-3xl text-center md:text-5xl">
          SPECIALNÍ MENU
          </h2>
          <div className="flex flex-col xl:flex-row gap-6">
          <img src = {image5} className="rounded-3xl w-64 md:w-96" onClick={() => toggleFullscreen(4)}></img>
          </div>
        </div>
        <div className="p-10 pb-24 flex flex-col items-center gap-6 bg-black w-screen">
          <h2 className="font-body font-bold text-white text-3xl text-center md:text-5xl">
          ODPOLEDNÍ MENU
          </h2>
          <div className="flex flex-col xl:flex-row gap-6">
          <img src = {image2} className="rounded-3xl w-64 md:w-96" onClick={() => toggleFullscreen(1)}></img>
          <img src = {image3} className="rounded-3xl w-64 md:w-96" onClick={() => toggleFullscreen(2)}></img>
          <img src = {image4} className="rounded-3xl w-64 md:w-96" onClick={() => toggleFullscreen(3)}></img>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Menu;
